import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import AuthenticatedApp from './AuthenticatedApp';

import './App.css';

function App() {
  return (
    <Authenticator hideSignUp>
      {({ signOut, user }) => (
        <div className="App">
          <AuthenticatedApp user={user} signOut={signOut} />
        </div>
      )}
    </Authenticator>
  );
}

export default App;
