import { useState } from 'react';
import { fetchAuthSession, AuthUser } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import axios from "axios";

import aws from './aws-exports.json';

function AuthenticatedApp({ user, signOut }: { user: AuthUser | undefined, signOut: (() => void) | undefined }) {
    const handleCallToggle = () => doCall("toggle");
    const handleCallGate = () => doCall("gate");

    const [lastStatus, setLastStatus] = useState<string>("");
    const [inProgress, setInProgress] = useState<boolean>(false);

    const doCall = async (path: string) => {

        const session = await fetchAuthSession();
        if (!session.tokens?.idToken) {
            setLastStatus("ERROR: Not authenticated");
            return;
        }
        console.log("calling", path, session.tokens.idToken);

        setLastStatus("Running...");
        setInProgress(true);
        const response = await axios.get(`${aws.BACKEND}box/${path}`, {
            headers: {
                Authorization: `Bearer ${session.tokens.idToken}`,
            },
        });

        console.log(response);

        setInProgress(false);
        setLastStatus(response.status === 200 ? "OK" : "Failed");
        setTimeout(() => {
            setLastStatus("");
        }, 5000);
    };

    return (
        <div style={{ padding: 20 }}>
            <p>
                Welcome {user?.username}
            </p>
            <p>
                <button onClick={signOut} disabled={inProgress}>Sign out</button>
            </p>
            <div style={{ height: 20 }}></div>
            <p>
                <button onClick={handleCallToggle} style={{ width: "100%", minHeight: 50 }} disabled={inProgress}>Open / Close Box</button>
            </p>
            <div style={{ height: 40 }}></div>
            <p>
                <button onClick={handleCallGate} style={{ width: "100%", minHeight: 50 }} disabled={inProgress}>Open Gate</button>
            </p>
            <div style={{ height: 60 }}></div>
            <p>{lastStatus}</p>
        </div>
    );
}

export default AuthenticatedApp;
